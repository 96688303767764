import './App.css';
import Menu from './Menu';
import { useTVList } from './useTVList.hook';

function App() {
  const TVList = useTVList();

  return (
    <div className="main-screen">
      {/* <button type='button' className='reload' onClick={reloadapp}>reload</button> */}
      {/* <pre style={{ background: '#ffffff' }}>{JSON.stringify(TVList.device,null,2)}</pre> */}
      {/* <pre style={{ background: '#ffffff' }}>{JSON.stringify(TVList.state, null, 2)}</pre> */}
      {/* <pre className='pre'>{JSON.stringify(TVList.log, null, 2)}</pre> */}
      {/* <pre className='pre' key={TVList.events.current.nbr}>{JSON.stringify(TVList.events.current.nbr, null, 2)}</pre> */}
      {TVList.device && ['2', '3', null].includes(TVList.device.appversion) ? null : <a href="https://tv.sim-software.tn/tv.apk" target="_blank" rel="noreferrer" download={`tv.apk`}>Télécharger APK</a>}
      {TVList.activeGroup ? <style key={TVList.activeGroup.key + 'activelem'}>{`#${TVList.activeGroup.key}{ background-color: rgba(0, 119, 255, 0.562); }`}</style> : null}
      <style key={TVList.focusElm}>{`#${TVList.focusElm}{ background-color: orange; }`}</style>
      {['ready', 'buffering'].includes(TVList.state && TVList.state.state) ? null : <style>{`.main-screen { background-image: url("/images/background.jpg"); background-size: cover; }`}</style>}
      <MainMenu />
      <Menu list={TVList.list} activeGroup={TVList.activeGroup} getFocusElem={TVList.getFocusElem} />
      <DetailBanner activeItem={TVList.activeItem} state={TVList.state} />
      <div className='loader-container'>
        <div className={`loader ${TVList.loading ? 'loading' : ''}`} >
          Chargement ...
        </div>
      </div>
      {TVList.styles.map(k => <style key={`active-elm-${k}`}>{`#${k}{ display: flex; }`}</style>)}
    </div>
  );
}

function MainMenu() {
  return (
    <div className='MainMenu'>
      <div className='btn-main btn-main-tv active'>TV</div>
      <div className='btn-main btn-main-setting'>Setting</div>
    </div>
  )
}

function DetailBanner({ activeItem, state }) {
  return (
    <div className='DetailBanner'>
      <span className='num'>{activeItem && activeItem.i}</span>
      <div className='title' >
        <h1>{activeItem && activeItem.title}</h1>
        <p>{activeItem && activeItem.group && activeItem.group.title}</p>
      </div>
      {activeItem && activeItem['tvg-logo'] ? <img src={activeItem['tvg-logo']} alt='' /> : null}
    </div>
  )
}


export default App;
