import { throttle } from "lodash";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import M3U from "./m3u";

function useScreenManager() {
    const [screens, setScreens] = useState({});
    const focusItem = useRef(null);
    const state = useRef({
        currentUrl: null,
    });
    const [device, setDevice] = useState(null);
    const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState(null);
    const [showMenu, setShowMenu] = useState(true);
    const [showDetails, setShowDetails] = useState(null);

    const submitdata = (action, data) => {
        data = data || {};
        data.action = action;
        if (window.Android && window.Android.dispatch) window.Android.dispatch(JSON.stringify(data));
    }

    useEffect(() => {
        let t = null;
        if (!showMenu && !showDetails && player && player.state === 'ready') {
            // t = setTimeout(() => submitdata("hideweb"), 1000);
        } else {
            submitdata("showweb");
        }
        return () => {
            submitdata("showweb");
            if (t) clearTimeout(t);
        }
    }, [showMenu, showDetails, player && player.state]);


    useEffect(() => {
        let t = null;
        if (showDetails) {
            if (state.current.playingItem) {
                t = setTimeout(() => {
                    setShowDetails(null);
                }, 4000);
            } else {
                setShowDetails(null);
            }
        }
        return () => {
            if (t) clearTimeout(t);
        }
    }, [showDetails]);


    useEffect(() => {
        if (!showMenu) {
            if (state.current.playingItem) {
                setShowDetails(state.current.playingItem);
            }
        } else {
            setShowDetails(null);
        }
    }, [showMenu, state.current.playingItem]);

    window.emitEvent = (eventName, data) => {
        if (data && data.toString && typeof data.toString === "string") {
            try {
                const r = data.toString.match(/keyCode=([^,]+),/i);
                if (r && r.length) {
                    data.key = `${r[1]}`.trim().toLowerCase();
                }
            } catch (e) { }
        }
        switch (eventName) {
            case 'device':
                setDevice(data);
                break;
            case 'playerInfos':
                setPlayer(data);
                break;
            case 'onKeyDown':
                state.current.eventCollector(data.key);
                break;
            default:
                submitdata("playerInfos");
                break;
        }
    }

    useEffect(() => {
        if (showMenu && !focusItem.current && screens && Array.isArray(screens.screens)) {
            screens.screens[0].click();
            screens.screens[0].screens[0].click();
        }
    }, [screens, showMenu, focusItem.current])

    const isLive = state.current.playingItem && player && !player.isCurrentWindowSeekable;
    const isVod = state.current.playingItem && player && player.isCurrentWindowSeekable;

    state.current.eventCollector = (key) => {
        key = key.trim().toLowerCase().replace(/arrow|keycode_(dpad_)?/ig, '');
        console.log(key);
        if (showMenu && focusItem.current) {
            focusItem.current.nav(key);
        } else {
            switch (key) {
                case 'back':
                    setShowMenu(true);
                    break;
                case 'right':
                    if (!isVod) setShowMenu(true);
                    break;
                case 'left':
                    if (!isVod) setShowMenu(true);
                    break;
                case 'enter':
                case 'center':
                    if (!isVod) setShowMenu(true);
                    break;
                case 'down':
                    if (!isVod && state.current.playingItem && state.current.playingItem.playPriv) {
                        state.current.playingItem.playPriv();
                    }
                    break;
                case 'up':
                    if (!isVod && state.current.playingItem && state.current.playingItem.playNext) {
                        state.current.playingItem.playNext();
                    }
                    break;
                default:
                    break;
            }
        }
    }

    const initScreen = (type, item, parent) => {
        item.active = false;
        item.type = type;
        item.key = `${item.id}-${Date.now()}`;
        item.set = () => {
            item.key = `${item.id}-${Date.now()}`;
            setScreens(s => Object.assign({}, s));
        };
        item.unselect = () => {
            item.active = false;
            if (Array.isArray(item.screens)) item.screens.map(elm => elm.unselect());
        }
        item.focus = () => {
            focusItem.current = item;
            const elm = document.getElementById(item.id);
            if (elm) {
                elm.focus();
                elm.scrollIntoViewIfNeeded();
            }
        }
        item.getFocus = () => focusItem.current;
        item.isFocus = () => {
            return focusItem.current && focusItem.current.id === item.id;
        }
        item.click = (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            parent.screens.map(elm => elm.unselect());
            item.active = true;
            item.focus();
            item.set();
            if (item.onEnter) item.onEnter(item);
        };

        item.getActiveType = (_type) => {
            if (!item.active) return null;
            if (item.type === _type) return item;
            if (!Array.isArray(item.screens)) return null;
            return item.screens.find(s => s.getActiveType(_type));
        };

        item.getUpElement = () => {
            if (parent.screens.indexOf(item) > 0) {
                return parent.screens[parent.screens.indexOf(item) - 1];
            }
            return item;
        }
        item.getRightElement = () => {
            if (Array.isArray(item.screens) && item.screens.length) {
                return item.screens[0];
            }
            return item;
        }
        item.getDownElement = () => {
            if (parent.screens.indexOf(item) < parent.screens.length - 1) {
                return parent.screens[parent.screens.indexOf(item) + 1];
            }
            return item;
        }
        item.getLeftElement = () => {
            return parent;
        }



        item.playPriv = () => {
            const elm = item.getUpElement();
            if (elm) {
                elm.click();
                elm.play();
                setShowMenu(false);
            }
        }

        item.playNext = () => {
            const elm = item.getDownElement();
            if (elm) {
                elm.click();
                elm.play();
                setShowMenu(false);
            }
        }

        item.play = () => {
            setPlayer((p) => {
                if (state.current.currentUrl !== item.url || (p && !['ready', 'buffering'].includes(p.state))) {
                    submitdata('play', { url: item.url });
                    state.current.playingItem = item;
                    state.current.currentUrl = item.url;
                    setShowDetails(item);
                }
                return p;
            });
        }

        item.nav = (key) => {
            switch (key) {
                case 'up':
                    item.getUpElement().click();
                    break;
                case 'right':
                    item.getRightElement().click();
                    break;
                case 'enter':
                    if (item.type === 'tv-chaine') {
                        item.play();
                        setShowMenu(false);
                    } else {
                        item.getRightElement().click();
                    }
                    break;
                case 'down':
                    item.getDownElement().click();
                    break;
                case 'left': {
                    let left = item.getLeftElement();
                    if (left.last) {
                        setShowMenu(false);
                    } else {
                        item.getLeftElement().click();
                    }
                }
                    break;
                case 'back':
                    setShowMenu(false);
                    break;
                default:
                    break;
            }
        }

        if (Array.isArray(item.screens)) {
            item.screens = item.screens.map(s => initScreen(item.childtype, s, item));
        }
        return item;
    }


    useEffect(() => {
        submitdata("device");
        submitdata("playerInfos");
        const pages = [];
        M3U.forEach((gr, i) => {
            const p = pages.length + 1;
            if (i % 30 === 0) {
                pages.push({
                    id: `page${p}`,
                    active: true,
                    data: {
                        label: `Page ${p}`
                    },
                    childtype: 'tv-group',
                    screens: []
                });
            }
            gr.num = (i + 1);
            gr.id = `tv-page${p}-gr${i}`;
            gr.childtype = 'tv-chaine';
            gr.screens = gr.tv.map((tv, j) => {
                tv.num = (j + 1);
                tv.id = `${gr.id}-tv${j}`;
                return tv;
            })
            pages[pages.length - 1].screens.push(gr);
        });

        const data = {
            last: true,
            childtype: 'group',
            screens: [
                {
                    id: 'tv',
                    data: {
                        label: 'TV'
                    },
                    active: true,
                    childtype: 'tv-page',
                    screens: pages
                },
                {
                    id: 'settings',
                    data: {
                        label: 'Settings'
                    },
                    childtype: 'get-m3u',
                    screens: [
                        {
                            id: 'get-m3u',
                            data: {
                                label: 'M3U File'
                            },
                            onEnter: (item) => {
                                setTimeout(() => {
                                    document.getElementById('m3u-input').focus();
                                }, 500)
                            }
                        }
                    ]
                }
            ]
        };
        data.screens = data.screens.map(s => initScreen('group', s, data))
        setScreens(data);

        const envFn = throttle((e) => {
            e.preventDefault();
            e.stopPropagation();
            state.current.eventCollector(`${e.key}`.replace('Arrow', '').toLowerCase().trim());
        }, 150, { leading: true });

        const ev = {
            keydown: (e) => {
                if (focusItem.current) focusItem.current.nbr = (focusItem.current.nbr || 0) + 1;
                envFn(e)
            }
        };

        Object.keys(ev).forEach((k) => {
            window.addEventListener(k, ev[k], false);
        });
        return () => {
            Object.keys(ev).forEach((k) => {
                window.removeEventListener(k, ev[k]);
            });
        }
    }, []);

    useEffect(() => {
        if (player && player.isCurrentWindowSeekable) {
            submitdata("showcontrols");
        } else {
            submitdata("hidecontrols");
        }
    }, [player && player.isCurrentWindowSeekable]);

    useEffect(() => {
        let t = null;

        if (player) {
            if (!player.active) {
                submitdata("stop");
                return;
            }
            if (player.state === 'buffering') {
                setLoading(true);
            } else {
                setLoading(false);
            }
        }

        if (player && (['unknown', 'idle'].includes(player.state) || (['ended'].includes(player.state) && !player.isCurrentWindowSeekable)) && state.current.currentUrl) {
            t = setTimeout(() => {
                if (state.current.playingItem) {
                    state.current.playingItem.play();
                }
            }, 5000);
        }

        return () => {
            if (t) clearTimeout(t);
        }
    }, [player && player.state, player && player.active, state.current.currentUrl]);

    return { screens, focusItem, loading, playerStatus: player ? player.state : null, showMenu, device, showDetails, state };
}

export function RenderScreens() {
    const { screens, playerStatus, loading, showMenu, showDetails, state } = useScreenManager();
    return (
        <MainScreen bg={!['ready', 'buffering'].includes(playerStatus) ? 1 : 0} className={`${showMenu ? 'showMenu' : ''} ${showDetails ? 'showDetails' : ''}`}>
            <img className="mylogo" src="https://simsd.sim-software.tn/images/logo.png" alt="" />
            <Loader className={loading ? 'loading' : ''} >
                <div>Chargement ...</div>
            </Loader>
            <div className="RenderListScreens">
                <RenderListScreens screens={screens.screens} childtype={screens.childtype} />
            </div>
            <Details item={state.current.playingItem} showDetails={showDetails} />
        </MainScreen>
    );
}

function Details({ item, showDetails }) {
    if (!item) return null;
    return (
        <DetailsDiv className={`${showDetails ? 'showDetails' : ''}`}>
            <span className="num">{item.num}</span>
            <div>
                <span className="title">{item.title}</span>
                <span className="group">{item.groupTitle}</span>
            </div>
            {item.logo ? <img src={item.logo} alt="" /> : null}
        </DetailsDiv>
    )
}

const DetailsDiv = styled.div`
    --active-bg-color: rgba(0, 119, 255, 0.562);
    --active-fg-color: #fff;
    height: 90px;
    border-radius: 10px;
    position: absolute;
    bottom: 7vh;
    left: 20vw;
    right: 20vw;
    background-color: rgba(0,0,0,0.5);
    border: 1px solid var(--active-bg-color);
    overflow: hidden;
    display: flex;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;

    visibility: hidden;
    transform: translateY(200px) scale(0);
    opacity: 0;

    &.showDetails {
        visibility: visible;
        transform: translateY(0) scale(1);
        opacity: 1;
    }

    & > .num {
        height: 90px;
        min-width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 10px;
        font-size: 40px;
        background-color: var(--active-bg-color);
        color: var(--active-fg-color);
    }
    & > div{
        margin-left: 10px;
        flex: 1;
        color: var(--active-fg-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
            font-size: 40px;
            font-weight: bold;
        }
        .group {
            font-size: 20px;
            margin-top: 2px;
        }
    }
    & > img {
        margin-left: 10px;
        max-height: 100%;
        max-width: 30%;
    }
`;

function RenderListScreens({ childtype, screens, parent }) {
    switch (childtype) {
        case 'group':
            const activeChildScreen = screens.find(s => s.active);
            return (
                <>
                    <div className={`screen-list screen-group ${screens.find(s => s.active) ? 'active' : ''} ${screens.find(s => s.focus) ? 'focus' : ''}`}>
                        {screens.map(item => <RenderScreen key={item.id} screen={item} />)}
                    </div>
                    {activeChildScreen ? <RenderListScreens childtype={activeChildScreen.childtype} screens={activeChildScreen.screens} parent={activeChildScreen} /> : null}
                </>
            );
        case 'tv-page': {
            const activeChildScreen = screens.find(s => s.active);
            const activeGroupe = screens.filter(s => s.active).map(s => s.getActiveType('tv-group')).find(s => s);
            return (
                <div className="tv-page-warpper">
                    <div className="tv-page-container">
                        <div className={`screen-list screen-tv-page ${screens.find(s => s.active) ? 'active' : ''} ${screens.find(s => s.focus) ? 'focus' : ''}`}>
                            {screens.map(item => <RenderScreen key={item.id} screen={item} />)}
                        </div>
                        {activeChildScreen ? <RenderListScreens childtype={activeChildScreen.childtype} screens={activeChildScreen.screens} parent={activeChildScreen} /> : null}
                        {activeGroupe ? (
                            <div className={`screen-list screen-tv-chaine ${activeGroupe.screens.find(s => s.active) ? 'active' : ''} ${activeGroupe.screens.find(s => s.focus) ? 'focus' : ''}`}>
                                {activeGroupe.screens.map(item => <RenderScreen key={item.id} childtype={item.type} screen={item} />)}
                            </div>
                        ) : null}
                    </div>
                    {parent.getFocus() && parent.getFocus()['tvg-logo'] ? <img className="image-logo-tv" key={parent.getFocus()['tvg-logo']} src={parent.getFocus()['tvg-logo']} alt="" /> : null}
                </div>
            );
        }
        case 'tv-group':
            return (
                <div className={`screen-list screen-tv-group ${screens.find(s => s.active) ? 'active' : ''} ${screens.find(s => s.focus) ? 'focus' : ''}`}>
                    {screens.map(item => <RenderScreen key={item.id} screen={item} />)}
                </div>
            );
        case 'get-m3u':
            return (
                <div className="bg-1">
                    {screens.map(item => <RenderScreen key={item.id} screen={item} />)}
                </div>
            );
        default:
            break;
    }
    return null;
}

function RenderScreen({ screen }) {
    switch (screen.type) {
        case 'group':
            return (
                <div className={`screen-item screen-item-group ${screen.active ? 'active' : ''} ${screen.isFocus() ? 'focus' : ''}`} id={screen.id} key={screen.key} onClick={screen.click}>
                    {screen.data.label}
                </div>
            );
        case 'tv-page':
            return (
                <div className={`screen-item screen-item-tv-page ${screen.active ? 'active' : ''} ${screen.isFocus() ? 'focus' : ''}`} id={screen.id} key={screen.key} onClick={screen.click}>
                    {screen.data.label}
                </div>
            );
        case 'tv-group':
            return (
                <div className={`screen-item screen-item-tv-group ${screen.active ? 'active' : ''} ${screen.isFocus() ? 'focus' : ''}`} id={screen.id} key={screen.key} onClick={screen.click}>
                    <span className="num">{screen.num}</span>
                    {screen['tvg-logo'] && `${screen['tvg-logo']}`.toLowerCase().startsWith('http') ? <img src={screen['tvg-logo']} alt="" /> : null}
                    <span>{screen['group-title']}</span>
                </div>
            )
        case 'tv-chaine':
            return (
                <div className={`screen-item screen-item-tv-chaine ${screen.active ? 'active' : ''} ${screen.isFocus() ? 'focus' : ''}`} id={screen.id} key={screen.key} onClick={screen.click}>
                    <span className="num">{screen.num}</span>
                    {screen['tvg-logo'] && `${screen['tvg-logo']}`.toLowerCase().startsWith('http') ? <img src={screen['tvg-logo']} alt="" /> : null}
                    <span>{screen['tvg-name']}</span>
                </div>
            );
        case 'get-m3u':
            return (
                <div style={{ minWidth: 400 }} className={`${screen.active ? 'active' : ''} ${screen.isFocus() ? 'focus' : ''}`} id={screen.id} key={screen.key} onClick={screen.click}>
                    <input className="input" id="m3u-input" placeholder="m3u" />
                </div>
            );
        default:
            break;
    }
    return null;
}


const MainScreen = styled.div`
     --active-bg-color: rgba(0, 119, 255, 0.562);
     --active-fg-color: #fff;

     --focus-bg-color: rgba(255,232, 0, 0.56);
     --focus-fg-color: #fff;

    ${props => props.bg ? 'background-image: url("/images/background.jpg");' : ''}
    ${props => props.bg ? 'background-size: cover;' : ''}

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    .bg-1 {
        border-radius: 10px;
        background-color: rgba(0,0, 0, 0.56);
        position: absolute;
        top: 15vh;
        bottom: 10vh;
        left: 90px;
        width: fit-content;
        display: flex;
        padding: 10px;
    }

    .RenderListScreens {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        visibility: hidden;
        transform: translateX(-200px);
        opacity: 0;
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -ms-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
    }

    &.showMenu > .RenderListScreens {
        visibility: visible;
        transform: translateX(0);
        opacity: 1;
    }

    .screen-list.screen-group {
        position: absolute;
        left: 0;
        width: 70px;
        top: 15vh;
        display: flex;
        flex-direction: column;
        .screen-item-group + .screen-item-group {
            margin-top: 10px;
        }
        .screen-item-group {
            background-color: rgba(0,0,0,0.7);
            color: #fff;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 90%;
            line-height: 30px;
            height: 50px;
            &.active {
                background-color: var(--active-bg-color);
                color: var(--active-fg-color);
            }
            &.focus {
                background-color: var(--focus-bg-color);
                color: var(--focus-fg-color);
            }
        }
    }

    .tv-page-warpper {
        position: absolute;
        top: 15vh;
        bottom: 10vh;
        left: 90px;
        width: fit-content;
        display: flex;
    }

    .tv-page-container {
        border-radius: 10px;
        background-color: rgba(0,0, 0, 0.56);
        color: #fff;
        display: flex;
        overflow: hidden;
        gap: 2px;
        min-width: 300px;
    }

    .screen-list.screen-tv-chaine, .screen-list.screen-tv-group, .screen-list.screen-tv-page {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .screen-item.screen-item-tv-chaine, .screen-item.screen-item-tv-group, .screen-item.screen-item-tv-page {
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        overflow: hidden;
        height: 40px;
        min-height: 40px;
        white-space: nowrap;
        text-overflow: hidden;
        align-items: center;
        padding-right: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        & > * + * {
            margin-left: 7px;
        }

        .num {
            background-color: rgba(255, 255, 255, 0.15);
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 35px;
            height: 40px;
            text-align: center;
            padding: 0 5px;
        }

        & > img {
            max-width: 25px;
            max-height: 90%;
        }

        &.active {
            background-color: var(--active-bg-color);
            color: var(--active-fg-color);
            .num {
                font-size: 25px;
            }
        }
        &.focus {
            background-color: var(--focus-bg-color);
            color: var(--focus-fg-color);
        }
    }

    .screen-item.screen-item-tv-page{
        padding: 0px 10px;
    }

    .image-logo-tv {
        max-width: 250px;
        max-height: 100%;
        align-self: flex-start;
        border-radius: 10px;
        margin-left: 15px;
    }
`;

const Loader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        position: absolute;
        background-color: rgba(0,0,0,0.4);
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        visibility: hidden;
        transform: translateY(-70px) scale(0);
        opacity: 0;
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -ms-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
    }

    &.loading > * {
        visibility: visible;
        transform: translateY(30px) scale(1);
        opacity: 1;
    }
`;


export default useScreenManager;
